<template>
  <div class="flex title flex-ai-c flex-jc-c font-w-b" style="width: 100%">
    <div :style="{ width: isEditTitle ? '100%' : '' }" class="position-re" ref="tableTitle">
      <template v-if="isEditTitle">
        <a-input
          ref="input"
          @change="handleChange"
          :value="value"
          @blur="blurHandler"
          @focus="isOpen = true"
          :placeholder="placeholder"
          :maxLength="20"
        />
        <a-select
          show-search
          autoFocus
          :open="isOpen"
          :showArrow="false"
          :getPopupContainer="getPopupContainer"
          :filterOption="false"
          @change="handleSelectChange"
        >
          <a-select-option v-for="i in selectOpt" :key="i.id" :value="i.id">
            <div class="option_content flex flex-jc-sb">
              {{ i.name }}
              <a-radio
                :checked="i.checked"
                @change="ChangeCheckedNum($event, i)"
              >默认</a-radio
              >
            </div>
          </a-select-option>
        </a-select>
      </template>

      <template v-else>
        <span @click="handleClickEditTitle" class="formStateName">{{ value }} </span>
      </template>
      <div style="color: #f5222d" v-if="!value" class="ant-form-explain">处理笺名称不能为空</div>
    </div>
    <div v-if="!isEditTitle" class="tio"><a-icon type="edit" />可编辑</div>
  </div>
</template>
<script>
import { documentTabListByType, documentTabSetting } from '@/api/modular/fileStream/processingSignature'
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '请输入处理笺名称'
        },
        addType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            isEditTitle: false,
            isOpen: false,
            checkedNum: 1,
            selectOpt: []
        }
    },
    methods: {
        handleClickEditTitle() {
            this.isEditTitle = true
            this.isOpen = true
            this.$nextTick(() => {
                this.$refs.input && this.$refs.input.focus()
            })
        },
        setDefaultLabel(value) {
            documentTabSetting({ id: value, type: this.addType })
            .then(res => {
                if (res.code === 200) {
                    this.$message.success('设置默认名称成功')
                    this.selectOpt.forEach(j => {
                        if (j.id === value) {
                            j.checked = true
                        } else {
                            j.checked = false
                        }
                    })
                }
            })
        },
        getSelectOpt(data) {
            documentTabListByType(this.addType)
            .then(res => {
                if (res.code === 200) {
                    this.selectOpt = res.data.map(j => ({ ...j, checked: j.name === data }))
                }
            })
        },
        handleChange({ target: { value } }) {
            this.$emit('input', value)
        },
        blurHandler() {
            this.isOpen = false
            if (this.value) this.isEditTitle = false
        },
        handleSelectChange(value, option) {
            this.$emit('input', this.selectOpt.find(j => j.id === value)?.name)
        },
        getPopupContainer() {
            return this.$refs.tableTitle
        },
        // 切换默认值
        ChangeCheckedNum({ target: { checked } }, { id }) {
            console.log('value++++++++++', checked)
            if (!checked) return
            this.setDefaultLabel(id)
        }
    }
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
}

.title {
    height: 100px;
    width: 100%;

    ::v-deep .ant-input {
        text-align: center !important;
        color: red;
        font-size: 26px;
    }

    ::v-deep .ant-select {
        display: none !important;
    }

    ::v-deep .ant-select-dropdown {
        padding: 5px 0;
        width: 50% !important;
        left: 50% !important;
        top: 50px !important;
        transform: translateX(-50%) !important;
    }

    ::v-deep .ant-select-dropdown-menu::-webkit-scrollbar {
        display: none;
    }

    .formStateName {
        color: red;
        font-size: 26px;
        width: auto;
        padding-right: 4px;
    }

    .tio {
        line-height: 1;
        color: rgb(216, 216, 216);
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    ::v-deep .ant-form-item {
        width: 100%;
    }
}
</style>
