import { axios } from '@/utils/request'
export const documentTabAdd = (data) => axios({
  url: '/documentTab/add',
  method: 'post',
  data
})
export const documentTabPage = (params) => axios({
  url: '/documentTab/page',
  method: 'get',
  params
})
export const documentTabDelete = (data) => axios({
  url: '/documentTab/delete',
  method: 'post',
  data
})
export const documentTabEdit = (data) => axios({
  url: '/documentTab/edit',
  method: 'post',
  data
})
export const documentTabListByType = (type) => axios({
  url: `/documentTab/list/${type}`,
  method: 'get'
})
export const documentTabSetting = (params) => axios({
  url: '/documentTab/setting',
  method: 'get',
  params
})
export const documentTabDefaultByType = (type) => axios({
  url: `/documentTab/default/${type}`,
  method: 'get'
})
