<template>
    <div class="manage_table">
        <a-form :form="form" id="table">
            <tableTitle ref="tableTitle" :addType="2" v-model="formState.name"></tableTitle>
            <table class="mytb">
                <tr class="thtt">
                    <th colspan="1">发文字号</th>
                    <td colspan="3" class="text-a-l">
                        <template>
                            <a-form-item label="">
                                <a-input
                                    :maxLength="40"
                                    v-decorator="['documentNumber', { rules: this.fastValidateRules.documentNumber }]"
                                    placeholder="请输入字号，例：〔2022〕88 号"
                                />
                            </a-form-item>
                        </template>
                    </td>
                </tr>
                <tr class="thtt">
                    <th colspan="1">紧急程度</th>
                    <td colspan="1" class="text-a-l">
                        <a-form-item>
                            <a-select
                                :disabled="isDisabled"
                                placeholder="请选择"
                                v-decorator="[
                                    'jjcd',
                                    {
                                        initialValue:
                                            (flow_urgency_degree.length > 0 &&
                                                flow_urgency_degree[flow_urgency_degree.length - 1].code) ||
                                            '1',
                                        rules: this.validateRules.urgencyDegree,
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) in flow_urgency_degree"
                                    :key="index"
                                    :value="item.code"
                                    >{{ item.value }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </td>
                    <th colspan="1">密级程度</th>
                    <td colspan="1" class="text-a-l">
                        <a-form-item>
                            <a-select
                                :disabled="isDisabled"
                                placeholder="请选择"
                                v-decorator="[
                                    'miji',
                                    {
                                        initialValue:
                                            (flow_secrecy_degree.length > 0 &&
                                                flow_secrecy_degree[flow_secrecy_degree.length - 1].code) ||
                                            '1',
                                        rules: this.validateRules.secrecyDegree,
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) in flow_secrecy_degree"
                                    :key="index"
                                    :value="item.code"
                                    >{{ item.value }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </td>
                </tr>
                <tr class="thtt">
                    <th colspan="1">拟稿单位</th>
                    <td colspan="3" class="text-a-l">
                        <a-form-item>
                            <!-- <a-input hidden v-decorator="['draftCompany']" /> -->
                            <div class="list_content">
                                <div class="flex">
                                    <div class="list_btn box-bb" v-if="!isDisabled">
                                        <a-button
                                            slot="suffix"
                                            icon="user"
                                            @click="
                                                () => {
                                                    updateFirstChangeState()
                                                    chooseDepart('ngdw_list', 1)
                                                }
                                            "
                                        ></a-button>
                                    </div>
                                    <template v-if="ngdw_list && ngdw_list.length > 0">
                                        <div v-for="(o, i) in ngdw_list" :key="'ngdw_list' + i" class="my-item">
                                            <a-tag class="tags flex0" color="blue">
                                                <span
                                                    class="tags flex0"
                                                    color="blue"
                                                    v-if="o.corpName"
                                                    :key="'ngdw_list_span' + i"
                                                    >{{ o.corpName }}</span
                                                >
                                                <OpenData
                                                    v-else
                                                    :type="o.type"
                                                    :openid="o.userId || o.id"
                                                    :corpid="o.corpId"
                                                ></OpenData>
                                                <a-button
                                                    v-if="!isDisabled"
                                                    type="link"
                                                    icon="close"
                                                    @click="
                                                        () => {
                                                            updateFirstChangeState()
                                                            ngdw_list.splice(i, 1)
                                                        }
                                                    "
                                                />
                                            </a-tag>
                                        </div>
                                    </template>
                                    <div class="inputs" v-else>
                                        <a-input
                                            :maxLength="30"
                                            v-model="formState.ngdwListInput"
                                            placeholder="请输入或选择拟稿单位"
                                            @change="
                                                () => {
                                                    updateFirstChangeState()
                                                }
                                            "
                                        ></a-input>
                                    </div>
                                </div>
                            </div>
                            <template v-if="draftCompanyValidateState || fromValidateState">
                                <div
                                    style="color: #f5222d"
                                    v-if="!getFormValue(formState.ngdwListInput || formState.draftCompany)"
                                    class="ant-form-explain"
                                >
                                    {{ this.fastValidateRules.draftCompany[0].message }}
                                </div>
                            </template>
                        </a-form-item>
                    </td>
                </tr>

                <tr class="thtt">
                    <th colspan="1">拟稿人</th>
                    <td colspan="1" class="text-a-l">
                        <a-form-item label="">
                            <!-- <a-input hidden v-decorator="['draftId']" /> -->
                            <div class="list_content">
                                <div v-for="(o, i) in ngr_list" :key="'ngr_list' + i" class="my-item">
                                    <a-tag class="tags flex0" color="blue">
                                        <OpenData
                                            :type="o.type"
                                            :openid="o.userId || o.id"
                                            :corpid="o.corpId"
                                        ></OpenData>
                                        <a-button
                                            v-if="!isDisabled"
                                            type="link"
                                            icon="close"
                                            @click="
                                                () => {
                                                    updateFirstChangeState()
                                                    ngr_list.splice(i, 1)
                                                }
                                            "
                                        />
                                    </a-tag>
                                </div>

                                <div class="list_btn box-bb" v-if="!isDisabled">
                                    <a-button
                                        slot="suffix"
                                        icon="user"
                                        @click="
                                            () => {
                                                updateFirstChangeState()
                                                showSelctDialog('ngr_list', 2)
                                            }
                                        "
                                    ></a-button>
                                </div>
                            </div>
                            <template v-if="draftIdValidateState || fromValidateState">
                                <div
                                    style="color: #f5222d"
                                    v-if="!getFormValue(formState.draftId)"
                                    class="ant-form-explain"
                                >
                                    {{ this.fastValidateRules.draftId[0].message }}
                                </div>
                            </template>
                        </a-form-item>
                    </td>
                    <th colspan="1">联系电话</th>
                    <td colspan="1" class="text-a-l">
                        <template>
                            <a-form-item label="">
                                <a-input
                                    :disabled="isDisabled"
                                    placeholder="请输入"
                                    v-decorator="['tellPhone', { rules: this.validateRules.tellPhone }]"
                                />
                            </a-form-item>
                        </template>
                    </td>
                </tr>

                <tr class="thtt">
                    <th colspan="1">核稿人</th>
                    <td colspan="3" class="text-a-l">
                        <a-form-item label="">
                            <!-- <a-input hidden v-decorator="['checkIds']" /> -->
                            <div class="list_content">
                                <div v-for="(o, i) in hgr_list" :key="'hgr_list' + i" class="my-item">
                                    <a-tag class="tags flex0" color="blue">
                                        <OpenData
                                            :type="o.type"
                                            :openid="o.userId || o.id"
                                            :corpid="o.corpId"
                                        ></OpenData>
                                        <a-button
                                            v-if="!isDisabled"
                                            type="link"
                                            icon="close"
                                            @click="
                                                () => {
                                                    updateFirstChangeState()
                                                    hgr_list.splice(i, 1)
                                                }
                                            "
                                        />
                                    </a-tag>
                                </div>

                                <div class="list_btn box-bb" v-if="!isDisabled">
                                    <a-button
                                        slot="suffix"
                                        icon="user"
                                        @click="
                                            () => {
                                                updateFirstChangeState()
                                                showSelctDialog('hgr_list', 2)
                                            }
                                        "
                                    ></a-button>
                                </div>
                            </div>
                            <template v-if="checkIdsValidateState || fromValidateState">
                                <div
                                    style="color: #f5222d"
                                    v-if="!getFormValue(formState.checkIds)"
                                    class="ant-form-explain"
                                >
                                    {{ this.fastValidateRules.checkIds[0].message }}
                                </div>
                            </template>
                        </a-form-item>
                    </td>
                </tr>

                <tr class="thtt">
                    <th colspan="1">文稿标题</th>
                    <td colspan="3" class="text-a-l">
                        <template>
                            <a-form-item label="">
                                <a-input
                                    :disabled="isDisabled"
                                    :maxLength="60"
                                    placeholder="请输入"
                                    v-decorator="['wgbt', { rules: this.validateRules.title }]"
                                />
                            </a-form-item>
                        </template>
                    </td>
                </tr>

                <tr class="tdtt align_l">
                    <th>正文</th>
                    <td colspan="3" class="text-a-l" @click="uploadFile(2)">
                        <template v-if="!(formState.attach && formState.attach.length)">
                            <a-form-item label="">
                                <a-upload
                                    name="file1"
                                    :customRequest="customRequestAttach"
                                    :openFileDialogOnClick="!loading1"
                                    :showUploadList="false"
                                    :maxCount="1"
                                >
                                    <div class="upload_text" v-if="!isDisabled">
                                        <a-button
                                            size="small"
                                            type="primary"
                                            ghost
                                            icon="cloud-download"
                                            :loading="loading"
                                        >
                                            上传正文
                                        </a-button>
                                    </div>
                                </a-upload>
                            </a-form-item>
                        </template>

                        <template v-else>
                            <div class="upload_file" v-for="(o, i) in formState.attach" :key="i">
                                {{ o.fileName }}
                                <a-button
                                    v-if="!isDisabled"
                                    type="link"
                                    icon="delete"
                                    @click="formState.attach.splice(i, 1)"
                                />
                            </div>
                        </template>
                    </td>
                </tr>
                <tr class="tdtt align_l cursor-df">
                    <th>附件</th>
                    <td colspan="3" class="text-a-l" @click="uploadFile(1)">
                        <a-upload
                            name="file1"
                            :customRequest="customRequestFileIds"
                            :openFileDialogOnClick="!loading"
                            :showUploadList="false"
                            :maxCount="1"
                        >
                            <div class="upload_text" v-if="!isDisabled">
                                <a-button size="small" type="primary" ghost icon="cloud-download" :loading="loading1">
                                    上传附件
                                </a-button>
                            </div>
                        </a-upload>
                        <div class="file_list">
                            <div class="upload_file" v-for="(o, i) in formState.fileIds" :key="i">
                                {{ o.fileName }}
                                <a-button
                                    v-if="!isDisabled"
                                    type="link"
                                    size="small"
                                    icon="delete"
                                    @click="formState.fileIds.splice(i, 1)"
                                />
                            </div>
                        </div>
                    </td>
                </tr>

                <tr class="thtt">
                    <th rowspan="2">内部会稿意见</th>
                    <td colspan="3" class="text-a-l box-bb" style="padding: 0">
                        <a-form-item label="">
                            <a-input
                                :maxLength="20"
                                :disabled="isDisabled"
                                placeholder="会搞单位："
                                v-decorator="['meetCompany', {}]"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <tr class="bigtt">
                    <td colspan="3" class="text-a-l box-bb">
                        <a-form-item label="">
                            <a-textarea
                                :disabled="isDisabled"
                                :maxLength="200"
                                placeholder="意见："
                                v-decorator="['meetDesc', {}]"
                                :autosize="{ minRows: 6, maxRows: 8 }"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <tr class="thtt">
                    <th rowspan="2">外部会稿意见</th>
                    <td colspan="3" class="text-a-l box-bb" style="padding: 0">
                        <a-form-item label="">
                            <a-input
                                :disabled="isDisabled"
                                :maxLength="20"
                                placeholder="会搞单位："
                                v-decorator="['outDraftCompany', {}]"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <tr class="bigtt">
                    <td colspan="3" class="text-a-l box-bb">
                        <a-form-item label="">
                            <a-textarea
                                :disabled="isDisabled"
                                placeholder="意见："
                                :maxLength="200"
                                v-decorator="['outDraftDesc', {}]"
                                :autosize="{ minRows: 6, maxRows: 8 }"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <template>
                    <!-- <tr v-for="(o, i) in list" :key="i" class="bigtt align_l">
            <th>{{ o.name }}</th>
            <td colspan="3" class="text-a-l">
              <a-tag color="#108ee9" v-for="tag in o.val" :key="tag.id">
                <OpenData :type="tag.type" :openid="tag.userId || tag.id" :corpid="tag.corpId"></OpenData>
              </a-tag>
            </td>
          </tr> -->
                </template>
            </table>
        </a-form>
        <!-- <a-divider /> -->
        <!-- <a-button type="primary" @click="preview">预览</a-button> -->
        <InputModal @getvalue="getvalue" ref="inputModal" />
        <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>
  
  <script>
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { InputModal } from '@/components'
import { handleUploadCommon, selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import tableTitle from '@/views/fileStream/documentManage/components/tableTitle.vue'
import { commonFromValidate, customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import { loadDefaultName } from './mixins'

import moment from 'moment'

export default {
    props: {
        todoType: {
            type: String,
            default: 'registration',
        },
        //收文信息
        info: {
            type: Object,
            default() {
                return {}
            },
        },
        // 不可编辑
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [loadDefaultName, selectPerson, commonFromValidate, customFromValidate, handleUploadCommon],
    components: {
        OpenData,
        InputModal,
        selectPersonDialog,
        tableTitle,
    },
    data() {
        return {
            form: this.$form.createForm(this),
            formState: {
                name: '发文稿纸',
                fileIds: [],
                attach: [],
                ngdwListInput: '',
            },
            moment,
            dom: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            list: [],
            fileList: [],
            fileAttachs: [],
            fileFileIdss: [],
            file_type: 1,
            upload_file_data: {},

            // 通讯录数据
            ngdw_list: [],
            ngdwListInput: '',
            ngr_list: [],
            hgr_list: [],
            loading: false,
            loading1: false,
            isPreview: false,
        }
    },
    computed: {},
    destroyed() {
        EventBus.$off('addDom', {})
    },
    created() {
        this.getDict()
        this.getList()
        this.getFromValidateState()
        this.documentTabDefaultByType(2, 'formState')
    },
    watch: {
        // 拟稿单位
        ngdw_list: {
            handler(val) {
                this.updateValidateState('draftCompany', !(val.length > 0))
                this.$set(this.formState, 'draftCompany', val)
            },
            deep: true,
            immediate: true,
        },
        // 拟稿人
        ngr_list: {
            handler(val) {
                this.updateValidateState('draftId', !(val.length > 0))
                this.$set(this.formState, 'draftId', val)
            },
            deep: true,
            immediate: true,
        },
        // 核稿人
        hgr_list: {
            handler(val) {
                this.updateValidateState('checkIds', !(val.length > 0))
                this.$set(this.formState, 'checkIds', val)
            },
            deep: true,
            immediate: true,
        },
        formState: {
            handler(val) {
                EventBus.$emit('form_state', val, this.form)
            },
            immediate: true,
        },
        'formState.ngdwListInput': {
            handler(val) {
                this.updateValidateState('draftCompany', !val)
            },
        },
        info: {
            handler(val) {
                const { draftId, checkIds, draftCompany } = val
                if (draftId) {
                    this.ngr_list = Array.isArray(draftId) ? draftId : []
                }
                if (checkIds) {
                    this.hgr_list = Array.isArray(checkIds) ? checkIds : []
                }
                if (draftCompany) {
                    this.ngdw_list = Array.isArray(draftCompany) ? draftCompany : []
                }
                this.formState = val
            },
            immediate: true,
            // deep: true,
        },
    },
    methods: {
        getFormObj() {
            const {
                form: { getFieldsValue },
            } = this
            let params = getFieldsValue()
            return {
                ...this.formState,
                ...params,
                ngr_list: this.ngr_list,
                hgr_list: this.hgr_list,
                ngdw_list: this.ngdw_list,
            }
        },
        // 处理日期
        disabledDateHandler(current) {
            return current < moment().subtract(1, 'days').endOf('day')
        },
        // 弹窗返回数据
        getvalue(data) {
            console.log('data++++++++++', data)
        },
        uploadFile(file_type) {
            this.file_type = +file_type
        },
        /**
         * 上传正文文件
         */
        customRequestAttach(data) {
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.attach)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上传失败：上传文件相同')
            }
            if (size) {
                this.$message.error('上传失败：文件大于100M')
                return
            }
            if (this.loading) return
            this.loading = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上传成功')
                        this.$set(this.formState, 'attach', [
                            { fileName: data.file.name, fileId: res.data, fileType: 2 },
                        ])
                        // this.$emit('update:fileIds', this.fileName)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        /**
         * 上传附件文件
         */
        customRequestFileIds(data) {
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.attach)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上传失败：上传文件相同')
            }
            if (size) {
                this.$message.error('上传失败：文件大于100M')
                return
            }
            if (this.loading1) return
            this.loading1 = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上传成功')
                        this.$set(this.formState, 'fileIds', [
                            ...this.formState.fileIds,
                            { fileName: data.file.name, fileId: res.data, fileType: 1 },
                        ])
                    }
                })
                .finally(() => {
                    this.loading1 = false
                })
        },
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        formatXzfn(xzfn) {
            return this.flow_file_type.find((j) => j.code === xzfn)?.value
        },
        onChange(date, dateString) {
            console.log(date, dateString)
        },
        getList() {
            EventBus.$on('addDom', (msg) => {
                this.list = msg
            })
        },
        chooseDepart(list, index) {
            this.type = 1
            this.selectPersonAndPart(list, index, 'single')
        },
        selectPersonAndPart(list, index, mode = 'multi') {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, this[list])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                mode,
                type: this.type, // 1 部门 其他 员工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                    this.formObj[list] = [...result, ...resPart]
                    if (list === 'ngdw_list') this.formState.ngdwListInput = ''
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 预览当前表格文件
        preview() {
            this.isPreview = !this.isPreview
            setTimeout(() => {
                let tableToPrint = document.getElementById('table') //将要被打印的表格
                let newWin = window.open('') //新打开一个空窗口
                newWin.document.write(tableToPrint.outerHTML) //将表格添加进新的窗口
                newWin.document.close() //在IE浏览器中使用必须添加这一句
                newWin.focus() //在IE浏览器中使用必须添加这一句
                newWin.print() //打印
                newWin.close() //关闭窗口
            })
        },
    },
}
</script>
  
  
  <style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.font {
    font-size: 16px;
}
.manage_table {
    .draft_content {
        flex-direction: column;
        .draft_company {
            flex: 1;
            // background: rebeccapurple;
            border-bottom: 1px solid #000;
        }
        .draft_proposal {
            flex: 3;
            // background-color: aquamarine;
        }
    }
    .list_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .my-item {
            // width: 55px;
            margin: 0 5px;
        }
        .list_btn {
            // width: 100%;
            padding: 0 5px;
            box-sizing: border-box;
        }
        .tags {
            height: 32px;
            line-height: 32px;
        }
    }
    ::v-deep .ant-form-item {
        margin-bottom: 0;
    }
    ::v-deep .ant-select-selection,
    ::v-deep .ant-input {
        border: none;
        box-shadow: none;
    }
    ::v-deep .ant-select-selection:focus,
    ::v-deep .ant-input:focus {
        outline: none !important;
        box-shadow: none;
    }
    ::v-deep .ant-input[disabled],
    ::v-deep .ant-select-disabled .ant-select-selection {
        background-color: #fff;
    }
}

.mytb {
    width: 100%;
    border: 1px solid black;
    font-size: 14px;
    table-layout: fixed;
    .upload_file {
        cursor: pointer;
        // margin-right: 8px;
    }
    th,
    td {
        text-align: center;
        border: 1px solid black;
    }
    .thtt {
        height: 50px;
    }
    .tdtt {
        height: 50px;
    }
    .bigtt {
        height: 150px;
        td {
            white-space: pre-line;
        }
    }
    .align_l {
        td {
            text-align: left;
            padding: 10px;
            vertical-align: text-top;
            white-space: pre-line;
        }
    }
}
.title {
    height: 100px;
    ::v-deep .ant-input {
        text-align: center;
        color: red;
        font-size: 26px;
    }
    ::v-deep .ant-form-item {
        width: 100%;
    }
}
.padding5 {
    padding: 0 10px;
}
</style>