import { axios } from '@/utils/request'

/**
 * 提醒附件的各种操作
 *
 */
 export function remindPeopleApi (parameter) {
    return axios({
      url: '/flow/documentBill/remindAccept',
      method: 'post',
      data: parameter
    })
}

/**
 * 公文发送修改重审
 *
 */
 export function editOfficialAudit (data) {
  return axios({
    url: '/flow/documentBill/editOfficialAudit',
    method: 'post',
    data
  })
}

