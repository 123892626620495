<template>
  <a-modal
    title="请选择载入数据来源"
    :visible="visible"
    :closable="false"
    :footer="null"
    :maskClosable="true"
    @cancel="handleCancel"
  >
    <div class="selectPerson">
      <div class="dialogSelect flex">
        <div class="left flex" @click="todoFromBook">
          <div class="tagContainer">
            <div class="icon">
              <a-icon type="usergroup-add" />
            </div>
            <div>通讯录</div>
          </div>
        </div>
        <div class="right left flex" @click="toSelectLabel">
          <div class="tagContainer">
            <div class="icon">
              <a-icon type="solution" />
            </div>
            <div>标签</div>
          </div>
        </div>
      </div>
      <selectPersonByLabel @submit="submit" ref="selectPersonByLabel" />
    </div>
  </a-modal>
</template>
<script>
import selectPersonByLabel from './selectPersonByLabel.vue'
export default {
  components: {
    selectPersonByLabel,
  },
  data() {
    return {
      visible: false,
      key: '',
      index: -1,
      all_list: [],
    }
  },
  methods: {
    todoFromBook() {
      this.$emit('selectTodoMsg', {
        type: 'book',
        data: {
          key: this.key,
          index: this.index,
          all_list: this.all_list,
        },
      })
      this.visible = false
    },
    submit(data) {
      const result = [...new Set(data)].map((j) => ({
        corpId: j.corpId,
        ...this.getDataKeyAndValue(j),
        type: j.deptId ? 'departmentName' : 'userName',
      }))
      this.$emit('selectTodoMsg', {
        type: 'label',
        data: {
          key: this.key,
          index: this.index,
          result,
        },
      })
      this.visible = false
    },
    getDataKeyAndValue(node) {
      const key = node.deptId ? 'id' : 'userId'
      return {
        [key]: node.deptId || node.userId,
      }
    },
    handleCancel() {
      this.visible = false
    },
    toSelectLabel() {
      this.$refs.selectPersonByLabel.open(this.key, this.index, [])
    },
    open({ key, index, all_list }) {
      this.visible = true
      this.key = key
      this.all_list = all_list
      this.index = index
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-title {
  text-align: center;
}
.selectPerson {
  font-size: 18px;
  .flex {
    display: flex;
    align-items: center;
  }
  .dialogSelect {
    width: 100%;
    .left {
      flex: 1;
      justify-content: center;
      .tagContainer {
        cursor: pointer;
        padding: 15px;
      }
    }
    .icon {
      font-size: 40px;
    }
  }
  .dialogCotent {
    width: 80%;
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
</style>
